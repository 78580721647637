<template>
  <b-tab title="Exceptions">
    <template v-if="apiResponse && apiResponse.cpApiResponse">
      <template v-if="apiResponse.cpApiResponse.validationErrors">
        <p
          v-for="(eError, eIndex) in apiResponse.cpApiResponse.validationErrors"
          :key="`eE-${eIndex}`"
          class="pl-2 text-danger"
        >
          {{ eError }}
        </p>
      </template>
      <template v-if="apiResponse.cpApiResponse.validationWarnings">
        <p
          v-for="(eWarning, wIndex) in apiResponse.cpApiResponse
            .validationWarnings"
          :key="`eW-${wIndex}`"
          class="pl-2"
        >
          {{ eWarning }}
        </p>
      </template>
      <p
        class="pl-2 theme-font-medium"
        v-if="apiResponse.cpApiResponse.errorMsg"
      >
        {{ apiResponse.cpApiResponse.errorMsg }}
      </p>
      <pre
        class="pl-2 mb-0 custom-scroll"
        v-if="apiResponse.cpApiResponse.stacktrace"
        >{{ apiResponse.cpApiResponse.stacktrace }}</pre
      >
    </template>
    <p v-else class="pl-2 color-black">No data available.</p>
  </b-tab>
</template>
<script>
export default {
  name: 'Exceptions',
  props: ['apiResponse']
}
</script>
